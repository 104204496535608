<template>
  <base-layout>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />

    <content-section :spacing="false">
      <div class="px-4 content-spacing">
        <title-plus
          :title="$t('components.organizationUsersManagement.headline')"
          :hide-plus="$acl.canNotUpdate('Users')"
          @plus="onOpenES"
          class="mb-10"
        />
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :qso="qso"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem class="flex items-center gap-1">
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: getDetailsPageRouteName(
                          item.role ? item.role.role_name : null
                        ),
                        params: { id: item.id },
                      }"
                      target="_blank"
                    >
                      {{ item.full_name }}
                    </router-link>
                    <span>
                      <i
                        v-if="item.is_active"
                        class="fas fa-check-circle text-green-600"
                        v-tooltip.top="`Verified`"
                      />
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        v-tooltip.top="`Unverified`"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.phone_number" />
                  <FSTableRowItem :text="item.email" />
                  <FSTableRowItem>
                    {{ getRoleName(item.roles) }}
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div v-if="item.last_login">
                      {{
                        getFormattedDateTime(
                          item.last_login,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                    <div v-else>-</div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <XStatus
                      :variant="
                        item.user_status === 'A'
                          ? 'green'
                          : item.user_status === 'D'
                          ? 'gray'
                          : 'orange'
                      "
                      :text="
                        item.user_status === 'A'
                          ? 'Active'
                          : item.user_status === 'D'
                          ? 'Inactive'
                          : item.user_status === 'H'
                          ? 'On Hold'
                          : '--'
                      "
                      profile="user"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="flex items-center gap-3">
                      <oto-edit-icon @click="onOpenES(item.id)" />
                      <UserIndexActions
                        :primary-key="itemIndex"
                        :data="item"
                        @accountStatusUpdated="
                          $store.dispatch('fsTable/fetchData')
                        "
                      />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem class="flex items-center gap-1">
                    <router-link
                      class="font-bold text-blue-600 capitalize"
                      :to="{
                        name: getDetailsPageRouteName(
                          item.role ? item.role.role_name : null
                        ),
                        params: { id: item.id },
                      }"
                      target="_blank"
                    >
                      {{ item.full_name }}
                    </router-link>
                    <span>
                      <i
                        v-if="item.is_active"
                        class="fas fa-check-circle text-green-600"
                        v-tooltip.top="`Verified`"
                      />
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        v-tooltip.top="`Unverified`"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    {{ getRoleName(item.roles) }}
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <XStatus
                      :variant="
                        item.user_status === 'A'
                          ? 'green'
                          : item.user_status === 'D'
                          ? 'gray'
                          : 'orange'
                      "
                      :text="
                        item.user_status === 'A'
                          ? 'Active'
                          : item.user_status === 'D'
                          ? 'Inactive'
                          : item.user_status === 'H'
                          ? 'On Hold'
                          : '--'
                      "
                      profile="user"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.organizationUsersManagement.table.columns.phoneNumber'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.phone_number || '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.organizationUsersManagement.table.columns.organizationEmail'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.email || '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.organizationUsersManagement.table.columns.lastLogin'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div v-if="item.last_login">
                          {{
                            getFormattedDateTime(
                              item.last_login,
                              'D MMM YYYY, h:mm:ss a'
                            )
                          }}
                        </div>
                        <div v-else>-</div>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.organizationUsersManagement.table.columns.actions'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div class="flex items-center gap-3">
                          <oto-edit-icon @click="onOpenES(item.id)" />
                          <UserIndexActions
                            :primary-key="itemIndex"
                            :data="item"
                            @accountStatusUpdated="
                              $store.dispatch('fsTable/fetchData')
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'

import OtoEditIcon from '@/components/ui/OtoEditIcon'
import XStatus from '@/components/badge/XStatus'
import { getFormattedDateTime } from '@/utils/datetime'
import UserAddEdit from './UserAddEdit'
import UserIndexActions from './UserIndexActions.vue'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
export default {
  name: 'UserIndex',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    XStatus,
    UserAddEdit,
    UserIndexActions,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      opened: [],
      fstId: 'UserIndex',
      qso: { append: '', prepend: '?' },
      indexDataEndpoint: this.$config.orgUser.api.index,
      tableHeaders: [
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.fullName'
          )}`,
          width: '23%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.phoneNumber'
          )}`,
          width: '`10`%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.organizationEmail'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.role'
          )}`,
          width: '17%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.lastLogin'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.actions'
          )}`,
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },

        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.fullName'
          )}`,
          width: '30%',
          sort: null,
        },

        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.role'
          )}`,
          width: '25%',
          sort: null,
        },

        {
          text: `${this.$t(
            'components.organizationUsersManagement.table.columns.status'
          )}`,
          width: '35%',
          sort: null,
        },
      ],

      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  async mounted() {
    window.addEventListener(this.$config.orgUser.events.refresh, () => {
      this.$store.dispatch('fsTable/fetchData')
    }),
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
      })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    // EdgeStack component
    async onOpenES(userId = null) {
      // if userId provided, open it in free (aka edit) mode, inject user data as well
      // otherwise, open it in strict (aka add mode). In both case, inject required data,
      // but user data is required in edit (free) mode.
      if (userId) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)

            // ** open a random step (keeping it as a reference)
            // function randomNumber(min, max) {
            //   const r = Math.random() * (max - min) + min
            //   return Math.floor(r)
            // }
            // this.$edgeStack.emitter.on(
            //   this.$edgeStack.getEventName('opened', this.ae.esId),
            //   () => {
            //     this.$xStepper.navigate(this.ae.esId).to(randomNumber(0, 4))
            //   }
            // )
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
    getDetailsPageRouteName(role) {
      // TODO: add franchise route
      // if (role === "Owner") return "ViewOrganizationUserProfile";
      // if (role === "Operator") return "ViewOperatorUserProfile";
      // return "ViewOrganizationUser";
      console.log(role)
      return 'ViewOrganizationUserProfile'
    },
    getRoleStatusVariant(role) {
      let collection = {
        Franchise: 'purple',
        Admin: 'yellow',
        Owner: 'red',
      }
      return role in collection ? collection[role] : 'purple'
    },
    getActionsEndpoint(id) {
      return this.$config.orgUser.api.update(id)
    },
    getRoleName(items) {
      if (items) {
        let roles = []
        items.forEach((item) => {
          roles.push(item.role_name)
        })
        return roles.join(', ')
      } else {
        return '--'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
